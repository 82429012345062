import StoryblokClient from 'storyblok-js-client'

let cache = null
export default function getStoryblokClient(isPreview) {
    if (cache === null) {
        let accessToken = process.env.NEXT_PUBLIC_SB_PUBLIC
        if (isPreview) {
            accessToken = process.env.NEXT_PUBLIC_SB_PREVIEW
        }
        cache = new StoryblokClient({
            accessToken,
            cache: {
                clear: 'auto',
                type: 'memory',
            },
        })
    }
    return cache
}

import gql from 'graphql-tag'
import { composeSlug, logGraphqlErrors, tryToParseObject } from '../utils/index'
import createGraphqlClient from '../apollo/createGraphqlClient'
import AppRouter from '../components/AppRouter'

const QUERY = gql`
    query initialData(
        $pageSlug: ID!,
        $headerSlug: ID!,
        $footerSlug: ID!,
    ) {
        pageStory: ContentNode(
            id: $pageSlug,
            resolve_links: "url",
        ) {
            id
            content
            translated_slugs {
                lang
                path
            }
            first_published_at
            default_full_slug
        }
        headerStory: HeaderItem(
            id: $headerSlug,
            resolve_links: "url"
        ) {
            id
            content {
                _uid
                _editable
                navigationItems
            }
        }
        footerStory: FooterItem(
            id: $footerSlug,
            resolve_links: "url"
        ) {
            id
            content {
                _uid
                _editable
                title
                groups
                partners
            }
        }
    }
`

export async function getServerSideProps(context) {
    const { query, locale } = context
    try {
        const isPreview = !!context.preview || !!query._storyblok
        const graphqlClient = createGraphqlClient(isPreview)
        const path = query.slug ? query.slug.join('/') : 'home'
        const pageSlug = composeSlug(locale, path)
        const headerSlug = composeSlug(locale, 'global/header')
        const footerSlug = composeSlug(locale, 'global/footer')
        const variables = {
            pageSlug,
            headerSlug,
            footerSlug,
        }
        const data = await graphqlClient.request(QUERY, variables)
        const {
            pageStory = {},
            headerStory,
            footerStory,
        } = data

        const { content } = pageStory
        const { game_id: gameIdFromUrl = null } = tryToParseObject(query)
        const isOddsPage = pageStory && pageStory.content.component === 'OddsPage'
        const gameIsFromStory = isPreview && parseInt(content.gameId, 10)
        const gameId = gameIdFromUrl || gameIsFromStory || null
        const hasGameId = gameId !== null
        if (isOddsPage && !hasGameId) {
            return {
                redirect: {
                    destination: '/'
                }
            }
        }

        return {
            props: {
                locale,
                isPreview,
                data: {
                    pageStory,
                    headerStory,
                    footerStory,
                },
                gameId,
            },
        }
    } catch (e) {
        logGraphqlErrors(e)
        throw e
    }
}

export default AppRouter

import React from 'react'
import SbEditable from 'storyblok-react'
import Button from '../utils/Button'
import useLinkWithParams from '../hooks/useLinkWithParams'
import usePageProps from '../hooks/usePageProps'

const SbButton = ({
    content,
}) => {
    const { link, text, size } = content
    const { isPreview } = usePageProps()
    const getLinkWithParams = useLinkWithParams()

    return (
        <SbEditable content={content}>
            <a
                href={getLinkWithParams(link)}
                onClick={(e) => {
                    if (isPreview) {
                        e.preventDefault()
                    }
                }}
            >
                <Button
                    size={size}
                    onClick={(e) => {
                        if (isPreview) {
                            e.preventDefault()
                        }
                    }}
                >
                    {text}
                </Button>
            </a>
        </SbEditable>
    )
}

export default SbButton

import React from 'react'
import Placeholder from './Placeholder'
import FeaturesSection from './FeaturesSection'
import HeadingSection from './HeadingSection'
import PowerPoolHeadingSection from './PowerPoolHeadingSection'

const Components = {
    FeaturesSection,
    HeadingSection,
    PowerPoolHeadingSection,
}

const DynamicSection = ({ blok }) => {
    if (typeof Components[blok.component] !== 'undefined') {
        const Component = Components[blok.component]
        return (
            <Component content={blok} />
        )
    }
    return <Placeholder componentName={blok.component} />
}

export default DynamicSection

import { useState } from 'react'

const useSlider = ({
    slideAmount,
}) => {
    const [activeSlide, setactiveSlide] = useState(0)

    const handleSlideSelect = (id) => {
        setactiveSlide(id)
    }

    const handleNextSlide = () => {
        if (activeSlide < slideAmount - 1) {
            setactiveSlide(activeSlide + 1)
        } else {
            setactiveSlide(0)
        }
    }

    const handlePreviousSlide = () => {
        if (activeSlide === 0) {
            setactiveSlide(slideAmount - 1)
        } else {
            setactiveSlide(activeSlide - 1)
        }
    }

    return {
        activeSlide,
        handleSlideSelect,
        handleNextSlide,
        handlePreviousSlide,
    }
}

export default useSlider

import React from 'react'
import { Trans } from '@lingui/macro'
import Head from '../../utils/Head'
import Footer from '../../utils/Footer'
import HeaderWithState from '../../utils/HeaderWithState'
import Page from '../../layout/Page'

const LoadingPage = () => (
    <Page>
        <Head />
        <HeaderWithState />
        <main>
            <section className="flex justify-center items-center container h-96">
                <h1 className="text-4xl font-marche">
                    <Trans>Loading...</Trans>
                </h1>
            </section>
        </main>
        <Footer />
    </Page>
)

export default LoadingPage

import { useRouter } from 'next/router'
import useAppState from './useAppState'

const useLanguageState = () => {
    const router = useRouter()
    const { changeLanguage } = useAppState()

    const handleChangeLanguage = (locale) => {
        if (!window) {
            return
        }
        changeLanguage(locale)
        document.cookie = `NEXT_LOCALE=${locale}`
        router.push(router.asPath, null, { locale })
    }

    return {
        ...router,
        handleChangeLanguage,
    }
}

export default useLanguageState

import { useRouter } from 'next/router'
import { getHrefFromLink } from '../../utils'

const useLinkWithParamsWithParams = () => {
    const { asPath } = useRouter()

    const getLinkWithCurrentParams = (link) => {
        const href = getHrefFromLink(link)
        const currentLinkArr = asPath.split('?')
        if (currentLinkArr.length === 1) {
            return href
        }
        const currentParams = currentLinkArr[1]
        const hrefArr = href.split('?')
        if (hrefArr.length > 1) {
            return `${href}&${currentParams}`
        }
        return `${href}?${currentParams}`
    }

    return getLinkWithCurrentParams
}

export default useLinkWithParamsWithParams

import React from 'react'
import SbEditable from 'storyblok-react'

const SbImage = ({
    content,
}) => {
    const {
        image,
    } = content

    if (image?.filename) {
        return (
            <SbEditable content={content}>
                <img
                    src={image.filename}
                    alt={image.name}
                />
            </SbEditable>
        )
    }
    return null
}

export default SbImage

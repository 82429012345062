import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import Button from '../utils/Button'
import Feature from '../utils/Feature'
import useLinkWithParams from '../hooks/useLinkWithParams'
import Image from '../utils/Image'

const FeaturesSection = ({
    content,
}) => {
    const [isOpen, setIsOpen] = useState(null)
    const getLinkWithParams = useLinkWithParams()

    const handleOpenFeature = (index) => {
        setIsOpen(index)
    }

    const handleCloseFeature = () => {
        setIsOpen(null)
    }

    const {
        title,
        subtitle,
        ctaLink,
        ctaText,
        features = [],
        phoneImage,
    } = content

    return (
        <SbEditable content={content}>
            <section className="relative">
                <div className="absolute inset-0 backdrop-filter backdrop-blur" />
                <div className="container flex">
                    <div className="hidden lg:flex flex-1 pr-4">
                        <div className="absolute top-0 -left-52 xl:-left-24 2xl:left-0 lg:h-[700px] z-0">
                            <div className="absolute inset-0">
                                {phoneImage?.filename ? (
                                    <Image
                                        width={1049}
                                        height={700}
                                        src={phoneImage.filename}
                                        alt="Mobile phone"
                                    />
                                ) : (
                                    <Image
                                        width={1049}
                                        height={700}
                                        src="/img/mobile.png"
                                        alt="Mobile phone"
                                    />
                                )}
                            </div>
                            <Image
                                width={1049}
                                height={700}
                                src="/img/lightning.png"
                                alt="Lightning"
                                className="absolute inset-0"
                            />
                        </div>
                    </div>
                    <div className="flex-1 py-12 z-1">
                        <div className="mb-16">
                            <h2 className="font-marcheBackSlant text-4xl md:text-5xl xl:text-6xl uppercase text-center lg:text-left -ml-3 pl-3">
                                {title}
                            </h2>
                            <h2 className="font-marcheBackSlant text-4xl md:text-5xl xl:text-6xl uppercase text-center lg:text-left text-yellow -ml-3 pl-3">
                                {subtitle}
                            </h2>
                        </div>
                        {features.map((featureContent, index) => (
                            <Feature
                                key={featureContent.title}
                                content={featureContent}
                                isOpen={isOpen === index}
                                index={index}
                                onCloseFeature={handleCloseFeature}
                                onOpenFeature={handleOpenFeature}
                                className="mb-12 lg:mb-24 text-xl"
                            >
                                {featureContent.description}
                            </Feature>
                        ))}
                        <div className="flex justify-center lg:justify-start lg:ml-20">
                            <Button
                                as="a"
                                href={getLinkWithParams(ctaLink)}
                                variant="primary"
                                size="larger"
                                className="text-xl lg:text-3xl"
                            >
                                {ctaText}
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default FeaturesSection

import React from 'react'
import SbEditable from 'storyblok-react'
import usePageProps from '../hooks/usePageProps'
import FooterGroup from './FooterGroup'
import FooterTitle from './FooterTitle'
import FooterPartner from './FooterPartner'

const Footer = ({
    content,
}) => {
    const { data } = usePageProps()
    const finalContent = content || data.footerStory.content
    const { footerType } = data.pageStory.content
    const { title, groups = [], partners } = finalContent
    return (
        <SbEditable content={finalContent}>
            <footer className="bg-black">
                <div className="container pt-4 lg:pt-14 grid grid-cols-3 gap-x-4 gap-y-8">
                    {partners !== null && (
                        <div className="col-span-3 justify-self-center">
                            <FooterTitle className="text-center">
                                {title}
                            </FooterTitle>
                            <div className="grid grid-cols-3 gap-4 grid-flow-col">
                                {partners.map((partner) => (
                                    <FooterPartner
                                        key={partner._uid}
                                        content={partner}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {groups.map((group, index) => (
                        <FooterGroup
                            key={group.title}
                            content={group}
                            index={index}
                            footerType={footerType}
                        />
                    ))}
                </div>
            </footer>
        </SbEditable>
    )
}

export default Footer

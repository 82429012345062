import { useRouter } from 'next/router'
import React from 'react'
import Link from './Link'

const paymentMethods = [{
    src: '/img/payment/apple-pay.png',
    name: 'Apple Pay'
}, {
    src: '/img/payment/bancontact-checkout.png',
    name: 'Bancontact checkout'
}, {
    src: '/img/payment/bancontact-docdata.png',
    name: 'Bancontact docdata'
}, {
    src: '/img/payment/bancontact-hipay.png',
    name: 'Bancontact hipay'
}, {
    src: '/img/payment/bancontact.png',
    name: 'Bancontact'
}, {
    src: '/img/payment/belfius.png',
    name: 'Belfius'
}, {
    src: '/img/payment/betfirst-prepaid.png',
    name: 'Betfirst prepaid'
}, {
    src: '/img/payment/betfirst-cash.png',
    name: 'Betfirst cash'
}, {
    src: '/img/payment/cashlib.png',
    name: 'Cashlib'
}, {
    src: '/img/payment/debit-card.png',
    name: 'Debit card'
}, {
    src: '/img/payment/ing.png',
    name: 'Ing'
}, {
    src: '/img/payment/much-better.png',
    name: 'Much better'
}, {
    src: '/img/payment/neteller.png',
    name: 'Neteller'
}, {
    src: '/img/payment/paypal.png',
    name: 'Paypal'
}, {
    href: '',
    src: '/img/payment/paysafe.png',
    name: 'Paysafe'
}, {
    src: '/img/payment/skrill.png',
    name: 'Skrill'
}, {
    src: '/img/payment/trustly.png',
    name: 'Trustly'
}, {
    src: '/img/payment/wire-transfer.png',
    name: 'Wire Transfer'
}]

const PaymentMethods = () => {
    const { locale } = useRouter()

    return (
        paymentMethods.map(({ href, src, name }) => (
            <Link
                key={`${href}/${src}/${name}`}
                href={`https://betfirst.dhnet.be/${locale}/support/payment-methods/`}
                rel="noreferrer"
            >
                <img
                    className="max-h-10 max-w-xs filter grayscale hover:grayscale-0 mb-6 mr-6"
                    src={src}
                    alt={name}
                />
            </Link>
        ))
    )
}

export default PaymentMethods

import React from 'react'
import clsx from 'clsx'

const FooterTitle = ({
    className,
    children
}) => (
    <h3
        className={clsx(
            'text-yellow font-marcheBackSlant uppercase text-xl mb-3',
            className
        )}
    >
        {children}
    </h3>
)

export default FooterTitle

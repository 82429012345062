import React from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import SbEditable from 'storyblok-react'
import { Plus, Minus } from './Icon'

const Feature = ({
    content,
    index,
    isOpen,
    onOpenFeature,
    onCloseFeature,
    className,
    children,
}) => (
    <SbEditable content={content}>
        <div className={clsx('flex', className)}>
            <div className="flex items-center justify-center pb-1 mr-10 flex-shrink-0 focus:outline-none focus:ring-2 ring-white">
                <button
                    type="button"
                    onClick={() => {
                        if (isOpen) {
                            onCloseFeature()
                        } else {
                            onOpenFeature(index)
                        }
                    }}
                    className="text-yellow flex self-start"
                >
                    {isOpen ? (
                        <Minus size="large" />
                    ) : (
                        <Plus size="large" />
                    )}
                </button>
            </div>
            <div>
                <h3 className="font-marcheBackSlant text-3xl sm:text-4xl">
                    {content.title}
                </h3>

                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                        >
                            <div className="pt-6">
                                {children}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    </SbEditable>
)

export default Feature

import React from 'react'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import loginAnimation from '../../animations/login.json'
import depositAnimation from '../../animations/deposit.json'
import mobileCasinoAnimation from '../../animations/mobile-casino.json'
import mobileSportsAnimation from '../../animations/mobile-sports.json'
import useLinkWithParams from '../hooks/useLinkWithParams'
import * as constants from '../../utils/constants'
import { ArrowDown } from './Icon'
import StepsMobile from './StepsMobile'
import StepsWithState from './StepsWithState'

const {
    ANIMATION_LOGIN: LOGIN,
    ANIMATION_DEPOSIT: DEPOSIT,
    ANIMATION_MOBILE_CASINO: MOBILE_CASINO,
    ANIMATION_MOBILE_SPORTS: MOBILE_SPORTS,
} = constants

const getAnimation = (slug) => {
    if (slug === LOGIN) {
        return loginAnimation
    }
    if (slug === DEPOSIT) {
        return depositAnimation
    }
    if (slug === MOBILE_CASINO) {
        return mobileCasinoAnimation
    }
    if (slug === MOBILE_SPORTS) {
        return mobileSportsAnimation
    }
    return null
}

const StepsContainer = ({
    steps = [],
}) => {
    const { locale } = useRouter()
    const getLinkWithParams = useLinkWithParams()
    const defaultSteps = [
        {
            title: <Trans>Register here</Trans>,
            link: `https://betfirst.dhnet.be/${locale}/?register=1&idaffiliation=1`,
            animation: loginAnimation,
            animationSlug: LOGIN,
        },
        {
            title: <Trans>Make your first deposit</Trans>,
            animation: depositAnimation,
            animationSlug: DEPOSIT,
        },
        {
            title: <Trans>Grab your chance</Trans>,
            animation: mobileSportsAnimation,
            animationSlug: MOBILE_SPORTS,
        },
    ]
    const stepsMapped = defaultSteps.map((defaultStep, index) => {
        const step = steps[index] || {}
        const link = step.link && getLinkWithParams(step.link)
        const hasLink = link && link !== '/'
        const hasText = step.title
        const hasAnimation = !!step.animationSlug
        return {
            ...defaultStep,
            ...step,
            sequence: index + 1,
            link: hasLink || hasText ? link : defaultStep.link,
            title: hasText ? step.title : defaultStep.title,
            animation: hasAnimation ? getAnimation(step.animationSlug) : defaultStep.animation,
            animationSlug: hasAnimation ? step.animationSlug : defaultStep.animationSlug,
        }
    })

    return (
        <>
            <div className="hidden md:block">
                <StepsWithState steps={stepsMapped} />
            </div>
            <div className="flex flex-col items-center md:hidden mb-4 relative">
                <StepsMobile className="mb-6" steps={stepsMapped} />
                <ArrowDown
                    size="larger"
                    className="text-yellow"
                />
            </div>
        </>
    )
}

export default StepsContainer

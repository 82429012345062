import React from 'react'
import LanguageSelect from './LanguageSelect'
import useLanguageState from '../hooks/useLanguageState'

const LanguageSelectWithState = ({
    ...props
}) => {
    const {
        locale,
        locales,
        handleChangeLanguage,
    } = useLanguageState()
    return (
        <LanguageSelect
            languages={locales}
            currentLanguage={locale}
            onChangeLanguage={handleChangeLanguage}
            {...props}
        />
    )
}

export default LanguageSelectWithState

import React, { Fragment } from 'react'
import clsx from 'clsx'
import { Menu, Transition } from '@headlessui/react'
import * as constants from '../../utils/constants'

const {
    LANGUAGE_EN: EN,
    LANGUAGE_FR: FR,
    LANGUAGE_NL: NL,
    LANGUAGE_TR: TR,
} = constants

const languageOptions = {
    [EN]: {
        label: EN,
    },
    [NL]: {
        label: NL,
    },
    [FR]: {
        label: FR,
    },
    [TR]: {
        label: TR,
    }
}

const LanguageSelect = ({
    languages,
    currentLanguage,
    onChangeLanguage,
    className,
}) => (
    <Menu
        as="div"
        className={clsx(
            'relative inline-block text-left text-xl',
            className,
        )}
    >
        {({ open }) => (
            <>
                <Menu.Button
                    className={clsx([
                        'uppercase flex items-center focus:outline-none focus:ring-2 ring-yellow px-2 py-1 transition-colors',
                        'hover:text-yellow',
                        open && 'text-yellow'
                    ])}
                >
                    {languageOptions[currentLanguage].label}
                    <div className={clsx([
                        'ml-2 ',
                        open ? 'triangle-up' : 'triangle-down',
                    ])}
                    />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-300"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-300"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-300"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute p-1 mt-2 origin-top-right bg-black lg:bg-transparent backdrop-filter backdrop-blur-xl focus:outline-none z-10 border-1 border-yellow min-w-full">
                        {languages.map((value) => (
                            <Menu.Item key={value}>
                                {({ active }) => (
                                    <button
                                        type="button"
                                        className={
                                            clsx([
                                                active ? 'text-yellow' : 'text-white',
                                                'group flex items-center w-full pl-2 pr-6 py-2 text-xl uppercase justify-center transition-colors',
                                            ])
                                        }
                                        onClick={() => onChangeLanguage(value)}
                                    >
                                        {value}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </>
        )}
    </Menu>
)

export default LanguageSelect

import React from 'react'
import clsx from 'clsx'
import Head from '../../utils/Head'
import Footer from '../../utils/Footer'
import HeaderWithState from '../../utils/HeaderWithState'
import DynamicSection from '../../content/DynamicSection'
import Page from '../../layout/Page'
import Image, { CLOUDINARY_PATH } from '../../utils/Image'
import StepsContainer from '../../utils/StepsContainer'
import * as constants from '../../../utils/constants'
import HeadingContainer from '../../layout/HeadingContainer'
import OddsPageDetails from './OddsPageDetails'
import OddsPageCarousel from './OddsPageCarousel'

const {
    IMAGE_LOAD_STATUS_ERROR: ERROR,
    IMAGE_LOAD_STATUS_LOADED: LOADED,
} = constants

const OddsPage = ({
    odds,
    totalGoals,
    bothTeamsScore,
    teamAway,
    teamHome,
    steps,
    actionUrl,
    showImage,
    onChangeImageStatusHome,
    onChangeImageStatusAway,
    body = [],
    background,
    logo,
    tagline,
    vsText,
    totalGoalsContent,
    bothTeamsScoreContent,
    navigationArrowDisabled,
    navigationArrowActive,
    navigationBulletActive,
    navigationBulletInactive,
    oddsBackground,
    oddsBackgroundHover,
}) => (
    <Page>
        <Head />
        <HeaderWithState />
        <main>
            <HeadingContainer>
                {background?.filename && (
                    <div className="fixed pt-40 xl:pt-32 top-0 bottom-0 h-screen w-screen -z-1">
                        <Image
                            placeholder="blur"
                            src={background.filename}
                            layout="fill"
                            objectFit="cover"
                        />
                    </div>
                )}
                <div className="flex flex-col justify-center relative min-h-[65vh]">
                    <div
                        className={clsx(
                            'hidden absolute w-[35rem] -left-36',
                            showImage ? 'md:block' : 'hidden',
                        )}
                    >
                        <Image
                            src={`${CLOUDINARY_PATH}/clubs/${teamHome.slug}.png`}
                            width={560}
                            height={560}
                            onLoad={() => onChangeImageStatusHome(LOADED)}
                            onError={() => onChangeImageStatusHome(ERROR)}
                        />
                    </div>
                    <div
                        className={clsx(
                            'hidden absolute w-[35rem] -right-36',
                            showImage ? 'md:block' : 'hidden',
                        )}
                    >
                        <Image
                            src={`${CLOUDINARY_PATH}/clubs/${teamAway.slug}.png`}
                            width={560}
                            height={560}
                            onLoad={() => onChangeImageStatusAway(LOADED)}
                            onError={() => onChangeImageStatusAway(ERROR)}
                        />
                    </div>
                    <div className="container mt-4">
                        <OddsPageDetails
                            logo={logo}
                            subTitle={tagline}
                            vsText={vsText}
                            teamAway={teamAway}
                            teamHome={teamHome}
                            className="mb-10"
                        />
                        <OddsPageCarousel
                            odds={odds}
                            totalGoals={totalGoals}
                            totalGoalsContent={totalGoalsContent}
                            bothTeamsScore={bothTeamsScore}
                            bothTeamsScoreContent={bothTeamsScoreContent}
                            actionUrl={actionUrl}
                            className="mb-10 lg:mb-16"
                            navigationArrowDisabled={navigationArrowDisabled}
                            navigationArrowActive={navigationArrowActive}
                            navigationBulletActive={navigationBulletActive}
                            navigationBulletInactive={navigationBulletInactive}
                            oddsBackground={oddsBackground}
                            oddsBackgroundHover={oddsBackgroundHover}
                        />
                    </div>
                </div>
                <StepsContainer steps={steps} />
            </HeadingContainer>

            {body.map((blok) => (
                <DynamicSection
                    key={blok._uid}
                    blok={blok}
                />
            ))}
        </main>
        <Footer />
    </Page>
)

export default OddsPage

import React, { useEffect, useRef } from 'react'
import Steps from './Steps'

const StepsWithState = ({
    steps,
}) => {
    const step1Ref = useRef()
    const step2Ref = useRef()
    const step3Ref = useRef()

    const stepRefs = [step1Ref, step2Ref, step3Ref]

    const handleCompleteStepAnimation = (step) => {
        setTimeout(() => {
            stepRefs[step].current.goToAndPlay(0)
        }, 1000)
    }

    useEffect(() => {
        step1Ref.current.play()
    }, [step1Ref.current])

    return (
        <Steps
            onCompleteStepAnimation={handleCompleteStepAnimation}
            step1Ref={step1Ref}
            step2Ref={step2Ref}
            step3Ref={step3Ref}
            steps={steps}
        />
    )
}

export default StepsWithState

export const where = (...keys) => (...values) => (obj) => keys.every((key) => (
    key.includes('!')
        ? !values.includes(obj[key.replace('!', '')])
        : values.includes(obj[key])
))

export const tryToParseObject = (obj) => (
    Object.keys(obj).reduce((acc, key) => {
        let value = obj[key]
        try {
            value = JSON.parse(obj[key])
        } catch (error) {
            // Silently fail
        }
        return {
            ...acc,
            [key]: value,
        }
    }, {})
)

const OS_MAC = 'Mac OS'
const OS_WINDOWS = 'Windows'
const OS_LINUX = 'Linux'
const OS_IOS = 'iOS'
const OS_ANDROID = 'Android'

export function getOS() {
    if (typeof window === 'undefined') {
        return null
    }
    const { userAgent } = window.navigator
    const { platform } = window.navigator
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = OS_MAC
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = OS_IOS
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = OS_WINDOWS
    } else if (/Android/.test(userAgent)) {
        os = OS_ANDROID
    } else if (!os && /Linux/.test(platform)) {
        os = OS_LINUX
    }

    return os
}

export const isWindows = getOS() === OS_WINDOWS

export const composeSlug = (locale, path) => {
    if (locale === 'en') {
        return path
    }
    return `${locale}/${path}`
}

export const logGraphqlErrors = ({ networkError, graphQLErrors }) => {
    let errors = []
    if (networkError) {
        const { result, bodyText } = networkError
        if (result) {
            errors = result.errors
        }
        if (bodyText) {
            errors = [{
                message: bodyText
            }]
        }
    }
    if (graphQLErrors && graphQLErrors.length > 0) {
        errors = graphQLErrors
    }
    // eslint-disable-next-line no-console
    console.log(errors)
}

export const formatLinkUrl = (url = null, anchor = null) => {
    if (url === null) {
        return '/'
    }
    // transforms 'my-url/' into '/my-url'
    let newUrl = url
    if (url.endsWith('/')) {
        newUrl = url.slice(0, -1)
    }
    return `/${newUrl}${anchor !== null ? `#${anchor}` : ''}`
}

export const getHrefFromLink = (link = null) => {
    if (link === null) {
        return '/'
    }
    if (link.linktype === 'url') {
        return link.url || '/'
    }
    const { story = null, anchor = null } = link
    if (story === null) {
        return '/'
    }
    return formatLinkUrl(story.url, anchor)
}

export const variant = (prop, variants) => (
    variants[prop]
)

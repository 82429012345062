import React from 'react'
import { Trans } from '@lingui/macro'
import Head from '../utils/Head'
import Footer from '../utils/Footer'
import HeaderWithState from '../utils/HeaderWithState'
import Page from './Page'

const PageNotFound = () => (
    <Page>
        <Head />
        <HeaderWithState />
        <main>
            <section className="flex flex-col justify-center items-center container h-96">
                <h1 className="text-8xl font-marche">
                    404
                </h1>
                <h2 className="text-4xl font-marche">
                    <Trans>Page not found.</Trans>
                </h2>
            </section>
        </main>
        <Footer />
    </Page>
)

export default PageNotFound

import React, { useState } from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import OddsPageTeamOdds from './OddsPageTeamOdds'
import OddsPageTotalGoals from './OddsPageTotalGoals'
import OddsPageBothTeamsScore from './OddsPageBothTeamsScore'
import NavigationArrow from '../../utils/NavigationArrow'
import Image from '../../utils/Image'

const getItems = ({
    odds,
    totalGoals,
    bothTeamsScore,
    actionUrl,
    totalGoalsContent,
    bothTeamsScoreContent,
    oddsBackground,
    oddsBackgroundHover,
}) => {
    const items = []
    const oddsBackgroundGroup = {
        oddsBackground,
        oddsBackgroundHover,
    }
    if (odds !== null) {
        items.push(
            <OddsPageTeamOdds
                odds={odds}
                actionUrl={actionUrl}
                oddsBackground={oddsBackgroundGroup}
            />
        )
    }
    if (totalGoals !== null) {
        items.push(
            <OddsPageTotalGoals
                actionUrl={actionUrl}
                totalGoals={totalGoals}
                totalGoalsContent={totalGoalsContent}
                oddsBackground={oddsBackgroundGroup}
            />
        )
    }
    if (bothTeamsScore !== null) {
        items.push(
            <OddsPageBothTeamsScore
                actionUrl={actionUrl}
                bothTeamsScore={bothTeamsScore}
                bothTeamsScoreContent={bothTeamsScoreContent}
                oddsBackground={oddsBackgroundGroup}
            />
        )
    }
    return items
}

const OddsPageCarousel = ({
    navigationArrowDisabled,
    navigationArrowActive,
    navigationBulletActive,
    navigationBulletInactive,
    className,
    ...props
}) => {
    const items = getItems(props)
    const [activeIndex, setActiveIndex] = useState(0)
    const activeItem = items[activeIndex]

    const handlePrevious = (e) => {
        e.preventDefault()
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1)
        }
    }

    const handleNext = (e) => {
        e.preventDefault()
        if (activeIndex < items.length - 1) {
            setActiveIndex(activeIndex + 1)
        }
    }

    const hasArrows = navigationArrowActive?.filename && navigationArrowDisabled?.filename
    return (
        <div
            className={clsx(
                'flex flex-col items-center',
                className
            )}
        >
            <div className="flex items-center relative mb-10 lg:mb-8">
                {items.length > 1 && hasArrows && (
                    <NavigationArrow
                        active={navigationArrowActive.filename}
                        disabled={navigationArrowDisabled.filename}
                        onClick={handlePrevious}
                        isDisabled={activeIndex === 0}
                        className="-left-5 transform scale-x-[-1]"
                    />
                )}
                <div className="flex-1 h-40 w-60 lg:w-[750px]">
                    <AnimatePresence initial={false}>
                        <motion.div
                            key={activeIndex}
                            className="flex absolute h-full w-60 lg:w-[750px]"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                                opacity: { duration: 0.5 }
                            }}
                        >
                            {activeItem}
                        </motion.div>
                    </AnimatePresence>
                </div>
                {items.length > 1 && hasArrows && (
                    <NavigationArrow
                        active={navigationArrowActive.filename}
                        disabled={navigationArrowDisabled.filename}
                        onClick={handleNext}
                        isDisabled={activeIndex === items.length - 1}
                        className="-right-5"
                    />
                )}
            </div>
            <div className="flex flex-row items-center justify-center">
                {items.length > 1 && Array.from(Array(items.length)
                    .keys())
                    .map((item, index) => {
                        const isActive = index === activeIndex
                        if (
                            navigationBulletActive?.filename
                            && navigationBulletInactive?.filename
                        ) {
                            return (
                                <button
                                    type="button"
                                    key={item}
                                    className="mx-3 w-4 h-2 relative"
                                    onClick={() => setActiveIndex(index)}
                                >
                                    <Image
                                        src={isActive
                                            ? navigationBulletActive.filename
                                            : navigationBulletInactive.filename}
                                        layout="fill"
                                        objectFit="contain"
                                        maxWidth={32}
                                    />
                                </button>
                            )
                        }
                        return null
                    })}
            </div>
        </div>
    )
}

export default OddsPageCarousel

import React from 'react'
import clsx from 'clsx'

export const ArrowDown = (props) => (
    <Icon {...props}>
        <path d="M11.75 1.5a.25.25 0 10-.5 0h.5zm-.25 21l-.186.167a.25.25 0 00.363.01L11.5 22.5zm5.177-4.823a.25.25 0 00-.354-.354l.354.354zm-9.491-.344a.25.25 0 10-.372.334l.372-.334zM11.25 1.5v21h.5v-21h-.5zm.427 21.177l5-5-.354-.354-5 5 .354.354zm.009-.344l-4.5-5-.372.334 4.5 5 .372-.334z" />
    </Icon>
)

export const Minus = (props) => (
    <Icon {...props}>
        <circle cx="12" cy="12" r="10.5" />
        <path d="M17 12H8" />
    </Icon>
)

export const Plus = (props) => (
    <Icon {...props}>
        <path d="M12 7v10M7 12h10" />
        <circle cx="12" cy="12" r="10.5" />
    </Icon>
)

const getStrokeWidth = (stroke, size) => {
    if (stroke !== 'default') {
        return ''
    }
    if (size === 'small') {
        return 2
    }
    return 1.75
}

const Icon = ({
    name,
    size = 'default',
    stroke = 'default',
    className,
    children,
    ...props
}) => (
    <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={getStrokeWidth(stroke, size)}
        viewBox="0 0 24 24"
        className={(clsx(
            size === 'largest' && 'h-12 w-12', // 48px
            size === 'larger' && 'h-10 w-10', // 40px
            size === 'large' && 'h-8 w-8', // 32px
            size === 'default' && 'h-6 w-6', // 24px
            size === 'small' && 'h-5 w-5', // 20px
            size === 'smaller' && 'h-4 w-4', // 20px
            className
        ))}
        {...props}
    >
        {children}
    </svg>
)

export default Icon

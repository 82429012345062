import React from 'react'
import PageUnknown from './layout/PageUnknown'
import PageNotFound from './layout/PageNotFound'
import DefaultPageWithState from './pages/default-page/DefaultPageWithState'
import OddsPage from './pages/odds-page/OddsPageWithState'
import Header from './layout/HeaderAsPage'
import Footer from './layout/FooterAsPage'

const Pages = {
    Page: DefaultPageWithState,
    OddsPage,
    Header,
    Footer,
}

const AppRouter = (props) => {
    const { data: { pageStory = null } } = props
    if (pageStory === null) {
        return <PageNotFound {...props} />
    }
    const { component } = pageStory.content
    const Page = Pages[component] || null
    if (Page === null) {
        return <PageUnknown {...props} />
    }
    return <Page {...props} />
}

export default AppRouter

import React from 'react'
import SbEditable from 'storyblok-react'
import clsx from 'clsx'
import StepsContainer from '../utils/StepsContainer'
import HeadingContainer from '../layout/HeadingContainer'
import Image from '../utils/Image'
import { variant } from '../../utils'
import DynamicHeading from './DynamicHeading'

const HeadingSection = ({
    content,
}) => {
    const {
        image,
        highlight = null,
        content: headingContent,
        container,
        overlayLeft,
        overlayLeftPosition,
        overlayRight,
        overlayRightPosition,
        overlayMobile,
        steps,
    } = content

    const hasHighlight = highlight?.filename
    const hasOverlayLeft = overlayLeft?.filename
    const hasOverlayRight = overlayRight?.filename
    const hasOverlayMobile = overlayMobile?.filename

    return (
        <HeadingContainer>
            <SbEditable content={content}>
                <div role="none" className="flex flex-col flex-1 justify-between">
                    <section className="relative">
                        <div className="overflow-hidden absolute inset-0">
                            {hasHighlight && (
                                <div className="absolute w-16 h-16 md:w-20 md:h-20 top-0 md:top-auto md:bottom-4 right-4">
                                    <Image
                                        height={75}
                                        width={75}
                                        src={highlight.filename}
                                        alt={highlight.name}
                                    />
                                </div>
                            )}
                            {image?.filename && (
                                <div className="hidden lg:block absolute -right-10 right- bottom-0 -z-1 h-[65vh] w-[860px]">
                                    <div className="relative w-full h-full">
                                        <Image
                                            src={image.filename}
                                            alt={image.name}
                                            layout="fill"
                                            objectFit="contain"
                                            objectPosition="right bottom"
                                            maxWidth={860}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {(hasOverlayLeft || hasOverlayRight) && (
                            <div className="hidden md:block absolute inset-0 z-1 pointer-events-none">
                                {hasOverlayLeft && (
                                    <div className="absolute w-1/4 h-full max-w-[18rem] pointer-events-auto">
                                        <Image
                                            src={overlayLeft.filename}
                                            alt={overlayLeft.name}
                                            layout="fill"
                                            objectFit="contain"
                                            objectPosition={overlayLeftPosition}
                                        />
                                    </div>
                                )}

                                {hasOverlayRight && (
                                    <div className="absolute w-1/4 h-full max-w-[18rem] right-0 pointer-events-auto">
                                        <Image
                                            src={overlayRight.filename}
                                            alt={overlayRight.name}
                                            layout="fill"
                                            objectFit="contain"
                                            objectPosition={overlayRightPosition}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="container flex lg:items-center relative min-h-[50vh] lg:min-h-[65vh]">
                            <div className={clsx(
                                'w-full flex flex-col items-center lg:items-start gap-2 md:gap-4 mx-auto lg:mx-0 pt-20 pb-4',
                                variant(container, {
                                    smallest: 'max-w-lg',
                                    smaller: 'max-w-xl',
                                    small: 'max-w-2xl',
                                    medium: 'max-w-3xl',
                                    large: 'max-w-4xl',
                                    larger: 'max-w-5xl',
                                    largest: 'max-w-6xl',
                                }),
                                hasOverlayLeft && 'lg:ml-[14rem]'
                            )}
                            >
                                {headingContent?.length > 0 && headingContent.map((blok) => (
                                    <DynamicHeading
                                        key={blok._uid}
                                        blok={blok}
                                    />
                                ))}
                            </div>
                        </div>
                    </section>
                    <div>
                        <div className="overflow-hidden">
                            <StepsContainer steps={steps} />
                        </div>
                        {hasOverlayMobile && (
                            <div className="w-full relative z-[2] pt-[60%] -mt-12 -mb-16 md:hidden">
                                <Image
                                    src={overlayMobile.filename}
                                    alt={overlayMobile.name}
                                    layout="fill"
                                    objectFit="contain"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </SbEditable>
        </HeadingContainer>
    )
}

export default HeadingSection

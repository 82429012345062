import React, { useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import ErrorPage from '../error/ErrorPage'
import LoadingPage from '../loading/LoadingPage'
import * as constants from '../../../utils/constants'
import useLanguageState from '../../hooks/useLanguageState'
import useOddsPageQuery from './useOddsPageQuery'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import OddsPage from './OddsPage'

const {
    IMAGE_LOAD_STATUS_ERROR: ERROR,
    IMAGE_LOAD_STATUS_LOADED: LOADED,
    IMAGE_LOAD_STATUS_LOADING: LOADING,
} = constants

const OddsPageWithState = ({
    data,
    gameId,
    resolveRelations,
}) => {
    const story = useStoryblokBridge(data.pageStory, resolveRelations)
    const { content } = story
    const {
        bothTeamsScoreTitle,
        underText,
        overText,
        totalGoalsTitle,
        yesText,
        noText,
    } = content
    const [imageDebounce, setImageDebounce] = useState(false)
    const [imageStatusHome, setImageStatusHome] = useState(LOADING)
    const [imageStatusAway, setImageStatusAway] = useState(LOADING)
    const variables = { gameId }
    const {
        odds,
        urls,
        teamHome,
        teamAway,
        totalGoals,
        bothTeamsScore,
        error,
        loading,
    } = useOddsPageQuery({ variables })
    const { locale } = useLanguageState()

    useEffect(() => {
        // Dirty hack to make sure image is loaded first
        // and then received potential error
        if (imageStatusHome === LOADED && imageStatusAway === LOADED) {
            setTimeout(() => {
                setImageDebounce(true)
            }, 500)
        }
    }, [imageStatusHome, imageStatusAway])

    if (loading) {
        return <LoadingPage />
    }

    if (gameId === null || typeof error !== 'undefined') {
        return <ErrorPage error={error} />
    }
    const showImage = imageStatusHome !== LOADING
        && imageStatusHome === LOADED
        && imageStatusHome !== ERROR
        && imageStatusAway !== LOADING
        && imageStatusAway === LOADED
        && imageStatusAway !== ERROR
        && imageDebounce === true

    return (
        <SbEditable content={content}>
            <OddsPage
                {...content}
                odds={odds}
                actionUrl={urls[locale] || '/'}
                teamAway={teamAway}
                teamHome={teamHome}
                totalGoals={totalGoals}
                totalGoalsContent={{
                    title: totalGoalsTitle,
                    underText,
                    overText,
                }}
                bothTeamsScore={bothTeamsScore}
                bothTeamsScoreContent={{
                    title: bothTeamsScoreTitle,
                    yesText,
                    noText,
                }}
                showImage={showImage}
                onChangeImageStatusHome={setImageStatusHome}
                onChangeImageStatusAway={setImageStatusAway}
            />
        </SbEditable>
    )
}

export default OddsPageWithState

import React from 'react'
import useStoryblokBridge from '../hooks/useStoryblokBridge'
import HeaderWithState from '../utils/HeaderWithState'
import Page from './Page'

const HeaderAsPage = ({
    data,
}) => {
    const { content } = useStoryblokBridge(data.headerStory)
    return (
        <Page>
            <HeaderWithState content={content} />
        </Page>
    )
}

export default HeaderAsPage

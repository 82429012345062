import React from 'react'
import clsx from 'clsx'
import Head from '../../utils/Head'
import Footer from '../../utils/Footer'
import HeaderWithState from '../../utils/HeaderWithState'
import DynamicSection from '../../content/DynamicSection'
import Page from '../../layout/Page'
import Image from '../../utils/Image'
import { variant } from '../../../utils'

const DefaultPage = ({
    body = [],
    background,
    backgroundPosition,
    backgroundMaxSize,
    backgroundOpacity,
    backgroundMobileOpacity,
}) => (
    <Page>
        <Head />
        <HeaderWithState />
        <main>
            {background?.filename && (
                <div className={clsx(
                    'fixed pt-24 xl:pt-32 top-0 bottom-0 h-screen w-screen -z-1',
                    variant(backgroundPosition, {
                        'right-10': 'lg:right-[10%]',
                        'left-10': 'lg:left-[10%]',
                        right: 'lg:right-0',
                        left: 'lg:left-0',
                        center: 'lg:left-1/2 lg:transform lg:-translate-x-1/2',
                    }),
                    variant(backgroundMaxSize, {
                        large: 'lg:max-w-6xl',
                        medium: 'lg:max-w-5xl',
                        small: 'lg:max-w-3xl',
                    }),
                    variant(backgroundMobileOpacity, {
                        50: 'opacity-50',
                    }),
                    variant(backgroundOpacity, {
                        50: 'md:opacity-50',
                        100: 'md:opacity-100',
                    }),
                )}
                >
                    <Image
                        placeholder="blur"
                        src={background.filename}
                        layout="fill"
                        className="object-right-center object-cover md:object-contain"
                    />
                </div>
            )}
            {body.map((blok) => (
                <DynamicSection
                    key={blok._uid}
                    blok={blok}
                />
            ))}
        </main>
        <Footer />
    </Page>
)

export default DefaultPage

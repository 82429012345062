import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import addStoryblokBridgeScript from '../../storyblok/addStoryblokBridgeScript'
import getStoryblokClient from '../../storyblok/getStoryblokClient'
import usePageProps from './usePageProps'

const DEFAULT_STORY = {
    content: {
        _uid: '',
        _editable: true,
        component: 'Unkown',
    }
}

export default function useStoryblokBridge(initialStory = DEFAULT_STORY, resolveRelations = '') {
    const { locale } = useRouter()
    const [story, setStory] = useState(initialStory)
    const { isPreview } = usePageProps()
    const initEventListeners = () => {
        const { StoryblokBridge, location } = window
        if (typeof StoryblokBridge !== 'undefined') {
            const storyblokBridge = new StoryblokBridge({
                resolveRelations
            })
            storyblokBridge.on(['change', 'published'], () => (
                location.reload(true)
            ))
            storyblokBridge.on('input', (event) => {
                if (event.story._uid === story._uid) {
                    setStory(event.story)
                }
            })
            const storyblokClient = getStoryblokClient(isPreview)
            storyblokBridge.on('enterEditmode', async (event) => {
                try {
                    const response = await storyblokClient
                        .get(`cdn/stories/${event.storyId}`, {
                            version: 'draft',
                            language: locale,
                            resolve_links: 'url',
                            resolve_relations: resolveRelations,
                        })
                    if (response.data.story && response.data.story.content) {
                        setStory(response.data.story)
                    }
                } catch (e) {
                    console.log(e)
                }
            })
        }
    }

    useEffect(() => {
        if (isPreview) {
            addStoryblokBridgeScript(initEventListeners)
        }
    }, [isPreview])

    useEffect(() => {
        setStory(initialStory)
    }, [initialStory])

    return story
}

import React from 'react'
import { Trans } from '@lingui/macro'
import Head from '../utils/Head'
import Footer from '../utils/Footer'
import HeaderWithState from '../utils/HeaderWithState'
import Page from './Page'

const PageUnknown = () => (
    <Page>
        <Head />
        <HeaderWithState />
        <main>
            <section className="flex flex-col justify-center items-center container h-96">
                <h1 className="text-6xl font-marche mb-6">
                    <Trans>Unknown page</Trans>
                </h1>
                <h2 className="text-3xl font-marche">
                    <Trans>Check if the page is added in AppRouter.</Trans>
                </h2>
            </section>
        </main>
        <Footer />
    </Page>
)

export default PageUnknown

import { Trans } from '@lingui/macro'
import React from 'react'
import SbEditable from 'storyblok-react'
import FooterTitle from './FooterTitle'
import PaymentMethods from './PaymentMethods'
import PlayResponsible from './PlayResponsible'
import RichTextField from './RichTextField'

const Before = ({ type }) => {
    switch (type) {
        case 'payment-methods':
            return <PaymentMethods />
        case 'play-responsible':
            return <PlayResponsible />
        default:
            return null
    }
}

const getTitle = (title, type, isSecondGroup) => {
    if (!isSecondGroup) {
        return title
    }
    switch (type) {
        case 'sports':
            return <Trans>Sports betting</Trans>
        case 'casino':
            return <Trans>Casino betting</Trans>
        default:
            return title
    }
}

const FooterGroup = ({
    content,
    index,
    footerType,
}) => {
    const {
        title,
        text,
        type,
    } = content
    const isSecondGroup = index === 1
    return (
        <SbEditable content={content}>
            <div className="col-span-3 lg:col-span-1 items-center justify-center">
                <FooterTitle className="text-center lg:text-left">
                    {getTitle(title, footerType, isSecondGroup)}
                </FooterTitle>
                <div className="flex flex-wrap justify-center lg:justify-start">
                    <Before type={type} />
                    {text && (
                        <RichTextField
                            className="mb-4 text-sm text-gray-500"
                            content={text}
                        />
                    )}
                </div>
            </div>
        </SbEditable>
    )
}

export default FooterGroup

import clsx from 'clsx'
import React from 'react'
import SbEditable from 'storyblok-react'
import { variant } from '../../utils'
// eslint-disable-next-line import/no-cycle
import DynamicHeading from './DynamicHeading'

const HeadingGroup = ({
    content,
}) => {
    const {
        margin,
        gap,
        items,
    } = content

    return (
        <SbEditable content={content}>
            <div
                className={clsx(
                    'flex flex-col',
                    variant(gap, {
                        small: 'gap-2',
                        medium: 'gap-8',
                        large: 'gap-14',
                    }),
                    variant(margin, {
                        small: 'my-2',
                        medium: 'my-8',
                        large: 'my-14',
                    }),
                )}
            >
                {items?.length > 0 && items.map((item) => (
                    <DynamicHeading
                        key={item._uid}
                        blok={item}
                    />
                ))}
            </div>
        </SbEditable>
    )
}

export default HeadingGroup

import React from 'react'
import clsx from 'clsx'
import { render, MARK_LINK } from 'storyblok-rich-text-react-renderer'
import Link from 'next/link'

export const resolvers = {
    markResolvers: {
        [MARK_LINK]: (children, props) => {
            const { href, target, linktype } = props
            if (linktype === 'email') {
                return <a href={`mailto:${href}`}>{children}</a>
            }
            if (href.match(/^(https?:)?\/\//)) {
                return <a href={href} target={target}>{children}</a>
            }
            return (
                <Link href={href}>
                    <a href={href}>{children}</a>
                </Link>
            )
        }
    }
}
const RichTextField = ({
    content,
    className,
}) => (
    <div
        className={clsx([
            'prose max-w-none',
            className,
        ])}
    >
        {render(content, {
            ...resolvers
        })}
    </div>
)

export default RichTextField

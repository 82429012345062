import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import Link from '../../utils/Link'
import Odds from '../../utils/Odds'

const OddsPageTotalGoals = ({
    actionUrl,
    totalGoals,
    totalGoalsContent: {
        title,
        underText,
        overText,
    },
    oddsBackground,
    className,
}) => {
    if (totalGoals === null) {
        return null
    }
    return (
        <div
            className={clsx(
                'grid grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 items-center font-marche uppercase',
                className,
            )}
        >
            <div className="col-span-2 lg:col-span-1 text-2xl text-center uppercase lg:text-4xl">
                {title || <Trans>Total number of goals</Trans>}
            </div>
            <div className="col-span-1 flex flex-col items-center">
                <div className="text-xl lg:text-4xl text-center">
                    {overText || <><Trans>More than</Trans>{' '}2.5</>}
                </div>
                <Link href={actionUrl}>
                    <Odds
                        className="cursor-pointer"
                        background={oddsBackground}
                    >
                        {parseFloat(totalGoals.over).toFixed(2)}
                    </Odds>
                </Link>
            </div>
            <div className="col-span-1 flex flex-col items-center">
                <div className="text-xl lg:text-4xl text-center">
                    {underText || <><Trans>Less than</Trans>{' '}2.5</>}
                </div>
                <Link href={actionUrl}>
                    <Odds
                        className="cursor-pointer"
                        background={oddsBackground}
                    >
                        {parseFloat(totalGoals.under).toFixed(2)}
                    </Odds>
                </Link>
            </div>
        </div>
    )
}

export default OddsPageTotalGoals

import React from 'react'
import Page from './Page'
import useStoryblokBridge from '../hooks/useStoryblokBridge'
import Footer from '../utils/Footer'

const FooterAsPage = ({
    data,
}) => {
    const { content } = useStoryblokBridge(data.footerStory)
    return (
        <Page>
            <Footer content={content} />
        </Page>
    )
}

export default FooterAsPage

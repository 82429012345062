import React from 'react'
import clsx from 'clsx'
import Link from '../../utils/Link'
import Odds from '../../utils/Odds'

const OddsPageTeamOdds = ({
    actionUrl,
    odds = null,
    oddsBackground,
    className,
}) => {
    if (odds === null) {
        return null
    }
    return (
        <div
            className={clsx(
                'flex flex-col items-center lg:flex-row font-marche uppercase w-full',
                className,
            )}
        >
            <div className="flex flex-row items-center lg:flex-col flex-1 w-full">
                <div className="flex-1 text-2xl lg:text-5xl text-center">
                    1
                </div>
                <div className="inline-flex flex-1 lg:mt-7">
                    <Link href={actionUrl}>
                        <Odds
                            className="cursor-pointer w-[75px] lg:min-w-[150px] lg:w-auto"
                            background={oddsBackground}
                        >
                            {parseFloat(odds.home).toFixed(2)}
                        </Odds>
                    </Link>
                </div>
            </div>
            <div className="flex flex-row items-center lg:flex-col flex-1 w-full">
                <div className="flex-1 text-2xl lg:text-5xl text-center">
                    x
                </div>
                <div className="relative inline-flex flex-1 mt-2 lg:mt-7 left-[10px] lg:left-0">
                    <Link href={actionUrl}>
                        <Odds
                            className="cursor-pointer w-[75px] lg:min-w-[150px] lg:w-auto"
                            background={oddsBackground}
                        >
                            {parseFloat(odds.draw).toFixed(2)}
                        </Odds>
                    </Link>
                </div>
            </div>
            <div className="flex flex-row items-center lg:flex-col flex-1 w-full">
                <div className="flex-1 text-2xl lg:text-5xl text-center">
                    2
                </div>
                <div className="relative inline-flex flex-1 mt-2 lg:mt-7 left-[20px] lg:left-0">
                    <Link href={actionUrl}>
                        <Odds
                            className="cursor-pointer w-[75px] lg:min-w-[150px] lg:w-auto"
                            background={oddsBackground}
                        >
                            {parseFloat(odds.away).toFixed(2)}
                        </Odds>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default OddsPageTeamOdds

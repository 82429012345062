import React from 'react'
import Link from './Link'

const Content = ({
    step,
}) => {
    if (typeof step.link !== 'undefined' && step.link !== '/') {
        return (
            <Link
                href={step.link}
                className="font-marcheBackSlant underline inline mr-2"
            >
                {step.title}
            </Link>
        )
    }

    return step.title
}

const StepTitle = ({
    step,
    number,
}) => (
    <div>
        {number}.
        {' '}
        <Content step={step} />
    </div>
)

export default StepTitle

import React from 'react'

export const formatCustomText = (
    text,
    {
        begin = '{',
        end = '}',
        className = '',
    }
) => (
    text.replace(begin, `<span class="${className}">`)
        .replace(end, '</span>')
)

const CustomText = ({
    as = 'span',
    beginSelector,
    endSelector,
    customText,
    customTextClassName,
    ...props
}) => {
    const Component = as
    return (
        <Component
            dangerouslySetInnerHTML={{
                __html: formatCustomText(
                    customText,
                    {
                        className: customTextClassName,
                        begin: beginSelector,
                        end: endSelector,
                    }
                )
            }}
            {...props}
        />
    )
}

export default CustomText

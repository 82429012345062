import React from 'react'
import Lottie from 'lottie-react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { variant } from '../../utils/index'
import * as constants from '../../utils/constants'
import StepTitle from './StepTitle'

const {
    ANIMATION_LOGIN: LOGIN,
    ANIMATION_DEPOSIT: DEPOSIT,
    ANIMATION_MOBILE_CASINO: MOBILE_CASINO,
    ANIMATION_MOBILE_SPORTS: MOBILE_SPORTS,
} = constants

const Step = ({
    children,
    content = {},
}) => (
    <SbEditable content={content}>
        <div className="flex flex-col font-marche uppercase text-3xl flex-1 text-center">
            {children}
        </div>
    </SbEditable>
)

const AnimationWrap = ({
    children,
    className,
}) => (
    <div
        className={clsx([
            'flex flex-col justify-center items-center h-24 mb-6',
            className,
        ])}
    >
        {children}
    </div>
)

const createAnimationVariant = (slug) => (
    variant(slug, {
        [LOGIN]: 'h-[2.75rem]',
        [DEPOSIT]: 'h-[4.43rem]',
        [MOBILE_CASINO]: 'h-[5.62rem]',
        [MOBILE_SPORTS]: 'h-[5.62rem]',
    })
)

const Steps = ({
    step1Ref,
    step2Ref,
    step3Ref,
    onCompleteStepAnimation,
    steps,
}) => (
    <section className="bg-opacity-50 bg-black backdrop-filter backdrop-blur-sm pt-8 pb-10">
        <div className="container flex">
            <Step content={steps[0]}>
                <AnimationWrap className="justify-end pb-2">
                    <Lottie
                        lottieRef={step1Ref}
                        animationData={steps[0].animation}
                        className={clsx(createAnimationVariant(steps[0].animationSlug))}
                        loop={false}
                        autoplay={false}
                        onComplete={() => onCompleteStepAnimation(1)}
                    />
                </AnimationWrap>
                <StepTitle
                    step={steps[0]}
                    number={1}
                />
            </Step>
            <Step content={steps[1]}>
                <AnimationWrap>
                    <Lottie
                        lottieRef={step2Ref}
                        animationData={steps[1].animation}
                        className={clsx(createAnimationVariant(steps[1].animationSlug))}
                        loop={false}
                        autoplay={false}
                        onComplete={() => onCompleteStepAnimation(2)}
                    />
                </AnimationWrap>
                <StepTitle
                    step={steps[1]}
                    number={2}
                />
            </Step>
            <Step content={steps[2]}>
                <AnimationWrap>
                    <Lottie
                        lottieRef={step3Ref}
                        animationData={steps[2].animation}
                        className={clsx(createAnimationVariant(steps[2].animationSlug))}
                        loop={false}
                        autoplay={false}
                        onComplete={() => onCompleteStepAnimation(0)}
                    />
                </AnimationWrap>
                <StepTitle
                    step={steps[2]}
                    number={3}
                />
            </Step>
        </div>
    </section>
)

export default Steps

import clsx from 'clsx'
import React from 'react'

const HeadingContainer = ({
    className,
    children,
}) => (
    <section
        className={clsx(
            'min-h-screen flex flex-col relative pt-24 -mt-24 xl:pt-32 xl:-mt-32',
            className
        )}
    >
        {children}
    </section>
)

export default HeadingContainer

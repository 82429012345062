import React from 'react'
import NextImage from 'next/image'

export const CLOUDINARY_PATH = 'https://res.cloudinary.com/betfirst/image/upload'

const cloudinaryLoader = ({
    src,
    quality,
}) => {
    if (src.includes('upload')) {
        // I removed the width property, because the image being fetched
        // was too large (> 100MP), so we need to rethink this strategy.
        const modifiers = `f_auto,c_fill,g_auto,q_${quality},dpr_auto`
        const [, dynamicPart] = src.split(CLOUDINARY_PATH)
        return `${CLOUDINARY_PATH}/${modifiers}/${dynamicPart}`
    }
    if (typeof window !== 'undefined' && !window.location.origin.includes('localhost')) {
        return `https://res.cloudinary.com/betfirst/image/fetch/f_auto/${window.location.origin}${src}`
    }

    return src
}

const createLoader = ({
    maxWidth
}) => ({
    src,
    width = 0,
    quality = 85,
}) => {
    let finalWidth = maxWidth
    if (!maxWidth || maxWidth > width) {
        finalWidth = width
    }
    const props = { src, width: finalWidth, quality }
    return cloudinaryLoader(props)
}

const Image = ({
    maxWidth,
    width,
    ...props
}) => (
    <NextImage
        loader={createLoader({
            maxWidth: maxWidth || width,
        })}
        width={width}
        {...props}
    />
)

export default Image

import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { getHrefFromLink } from '../../utils'
import Link from './Link'

const FooterPartner = ({
    content,
    className,
    ...props
}) => {
    const { link, name, image } = content
    return (
        <SbEditable content={content}>
            <Link
                href={getHrefFromLink(link)}
                rel="noreferrer"
                className={clsx('mx-auto', className)}
                {...props}
            >
                {image && (
                    <img
                        className="lg:max-h-16 lg:max-w-xs filter grayscale hover:grayscale-0"
                        src={image?.filename}
                        alt={name}
                    />
                )}
            </Link>
        </SbEditable>
    )
}

export default FooterPartner

import React from 'react'

const Page = ({
    children,
}) => (
    <div className="pt-24 xl:pt-32 overflow-hidden" role="none">
        {children}
    </div>
)

export default Page

import React from 'react'
import clsx from 'clsx'

const Button = ({
    as = 'button',
    size = 'default',
    variant = 'primary',
    className,
    children,
    ...props
}) => {
    const Component = as
    return (
        <Component
            type="button"
            className={clsx(
                'border-2 font-montserrat transform skew-x-12 transition ease-out duration-300',
                variant === 'primary' && 'text-black border-transparent bg-yellow hover:bg-yellow-light',
                variant === 'secondary' && 'text-white border-gray-500 bg-white bg-opacity-25 hover:bg-opacity-75 hover:text-black',
                size === 'default' && 'py-1 px-9',
                size === 'large' && 'pt-2 pb-1 px-10',
                size === 'larger' && 'py-3 px-12 uppercase text-3xl',
                size === 'largest' && 'py-5 px-16 uppercase text-3xl',
                className,
            )}
            {...props}
        >
            <div className="transform -skew-x-12 relative">
                {children}
            </div>
        </Component>
    )
}

export default Button

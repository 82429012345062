import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import Lottie from 'lottie-react'
import { motion } from 'framer-motion'
import useSlider from '../hooks/useSlider'

const StepsMobile = ({
    className,
    steps,
}) => {
    const [dragStartX, setDragStartX] = useState(0)
    const [sliderWidth, setSliderWidth] = useState()
    const [lastWidth, setLastWidth] = useState(0)
    const [coordinates, setCoordinates] = useState([])
    const stepsButtons = useRef({})
    const {
        activeSlide,
        handleNextSlide,
        handlePreviousSlide,
        handleSlideSelect,
    } = useSlider({ slideAmount: steps.length })

    const calculateWidth = () => {
        const values = Object.values(stepsButtons.current)
        let totalWidth = 0
        const newCoordinates = values.reduce((acc, button) => {
            const buttonStyle = window.getComputedStyle(button)
            const margin = parseInt(buttonStyle.getPropertyValue('margin-right'), 10)
            const width = button.offsetWidth + margin
            totalWidth += width
            return [
                ...acc,
                acc[acc.length - 1] + width,
            ]
        }, [0])

        const newLastWidth = values[values.length - 1].offsetWidth
        setLastWidth(newLastWidth)
        totalWidth += newLastWidth

        setCoordinates(newCoordinates)
        setSliderWidth(totalWidth)
    }

    useEffect(() => {
        calculateWidth()
    }, [stepsButtons])

    const last = coordinates[coordinates.length - 1]
    const selectedCoordinates = coordinates[activeSlide]
    return (
        <div className={clsx('container', className)}>
            <motion.div
                className="flex flex-wrap relative left-1/4 sm:left-1/3 transform translate-x-1/4 sm:translate-x-1/3 mb-6"
                style={{
                    width: sliderWidth,
                }}
                animate={{
                    x: typeof selectedCoordinates !== 'undefined'
                        ? -selectedCoordinates
                        : 0,
                }}
                drag="x"
                dragConstraints={{
                    left: -last + lastWidth,
                    right: 0,
                }}
                onDragStart={(event) => setDragStartX(event.clientX)}
                onDragEnd={(event) => {
                    if (dragStartX > event.clientX && activeSlide < steps.length - 1) {
                        handleNextSlide()
                    }
                    if (dragStartX < event.clientX && activeSlide > 0) {
                        handlePreviousSlide()
                    }
                }}
            >
                {steps
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((step, index) => {
                        const isActive = activeSlide === index
                        const isLast = index === steps.length - 1 // zero index based
                        return (
                            <button
                                key={step.sequence}
                                ref={(ref) => { stepsButtons.current[index] = ref }}
                                type="button"
                                onClick={() => handleSlideSelect(index)}
                                className={clsx([
                                    'font-marcheBackSlant relative w-40 text-md py-3 px-5 uppercase focus:outline-none inline-block',
                                    !isLast && 'mr-4',
                                ])}
                            >
                                <Lottie
                                    animationData={step.animation}
                                    loop={isActive}
                                    autoplay={isActive}
                                    className="h-12"
                                />
                                <div
                                    className={
                                        clsx(
                                            'absolute w-full h-full top-0 left-0 border-2 transform skew-x-12',
                                            isActive ? 'border-yellow' : 'border-gray-300'
                                        )
                                    }
                                />
                                <span className="text-yellow mr-1">{step.sequence}.</span>
                                {step.title}
                            </button>
                        )
                    })}
            </motion.div>
            <div className="flex justify-center">
                {steps
                    .sort((a, b) => (a.sequence - b.sequence))
                    .map((step, index) => {
                        const isActive = activeSlide === index
                        const isLast = index === steps.length - 1 // zero index based
                        return (
                            <button
                                key={step.sequence}
                                type="button"
                                onClick={() => handleSlideSelect(index)}
                                className={
                                    clsx(
                                        'h-3 w-3 rounded-full border-1 focus:outline-none',
                                        isActive ? 'bg-yellow border-yellow' : 'bg-transparent border-gray-300',
                                        !isLast && 'mr-4',
                                    )
                                }
                            >
                                <span />
                            </button>
                        )
                    })}
            </div>
        </div>
    )
}

export default StepsMobile

import React from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { DEFAULT_SITE_NAME, } from '../../utils/constants'

const Head = ({
    title,
    description,
    keywords,
    authors,
    themeColor,
}) => {
    const { i18n } = useLingui()
    const router = useRouter()
    const { locale } = router

    const headTitle = title || i18n._(t`Sports Betting | Online Betting | betFIRST`)
    const headDescription = description || i18n._(t`Sports Betting | Online Betting | betFIRST`)

    return (
        <NextHead>
            <meta name="robots" content="noindex,nofollow" />
            <meta name="viewport" content="minimum-scale=1, width=device-width, initial-scale=1, user-scalable=0" />

            <title>{headTitle}</title>

            <meta name="description" content={headDescription} />
            <meta name="keywords" content={keywords} />
            <meta name="author" content={authors} />
            <meta name="theme-color" content={themeColor} />
            <meta property="og:locale" content={locale} />
            <meta property="og:site_name" content={DEFAULT_SITE_NAME} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={headTitle} />
            <meta property="og:description" content={headDescription} />

            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0F0C19" />

            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />

            <meta name="facebook-domain-verification" content="yudw0909xdezor9e37tpjqctz56m3c" />

            <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
            <link rel="shortcut icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="shortcut icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />

            <link
                rel="preload"
                href="/fonts/Marche-Super.woff"
                as="font"
                crossOrigin=""
            />
            <link
                rel="preload"
                href="/fonts/Marche-Super.woff2"
                as="font"
                crossOrigin=""
            />
            <link
                rel="preload"
                href="/fonts/Marche-SuperBackslant.woff"
                as="font"
                crossOrigin=""
            />
            <link
                rel="preload"
                href="/fonts/Marche-SuperBackslant.woff2"
                as="font"
                crossOrigin=""
            />
        </NextHead>
    )
}

export default Head

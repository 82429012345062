import React from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import usePageProps from '../hooks/usePageProps'

const Link = ({
    children,
    onClick,
    ...props
}) => {
    const { isPreview } = usePageProps()
    const { query } = useRouter()
    const queryWithoutSlug = Object.keys(query).filter((key) => key !== 'slug')
    const queryParams = queryWithoutSlug.map((key) => (
        `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )).join('&')
    let href = `${props.href}?&${queryParams}`
    if (props.href.indexOf('?') >= 0) {
        href = `${props.href}&${queryParams}`
    }
    return (
        <NextLink
            href={href}
            passHref
        >
            <a
                href={href}
                onClick={(e) => {
                    if (isPreview) {
                        e.preventDefault()
                    }
                    if (!isPreview && typeof onClick === 'function') {
                        onClick(e)
                    }
                }}
                {...props}
            >
                {children}
            </a>
        </NextLink>
    )
}

export default Link

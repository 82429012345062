import React, { useState } from 'react'
import clsx from 'clsx'

const Odds = ({
    className,
    background: {
        oddsBackgroundHover,
        oddsBackground,
    },
    children,
}) => {
    const [isHovered, setIsHovered] = useState(false)
    const background = isHovered ? oddsBackgroundHover.filename : oddsBackground.filename
    const handleOnMouseEnter = () => setIsHovered(true)
    const handleOnMouseLeave = () => setIsHovered(false)
    return (
        <div
            className={
                clsx([
                    'relative h-12 w-20 lg:h-16 lg:w-40 text-2xl z-1 transition-all duration-300',
                    'bg-contain bg-center bg-no-repeat',
                    'text-yellow hover:text-black',
                    'lg:text-5xl lg:bg-transparent ',
                    className,
                ])
            }
            style={{
                backgroundImage: `url('${background}')`
            }}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
        >
            <div className="absolute inset-0 w-full flex items-center justify-center align-middle font-montserrat-slanted">
                {children}
            </div>
        </div>
    )
}

export default Odds

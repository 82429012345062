import React from 'react'
import Link from './Link'

const responsibleGaming = [{
    href: 'https://playsafe.be/',
    src: '/img/rg/play_legally.png',
    name: 'Play safe'
}, {
    href: 'https://www.gamingcommission.be/opencms/opencms/jhksweb_fr/home/',
    src: '/img/rg/moderation.png',
    name: '18+ Play with moderation'
}, {
    href: 'https://www.gamingcommission.be/opencms/opencms/jhksweb_fr/protection/',
    src: '/img/rg/commission-en.png',
    name: 'Gaming commission jeu responsable'
}, {
    src: '/img/rg/responsible-gaming-en.png',
    name: 'Commission des jeux de hasard'
}]

const PlayResponsible = () => (
    <div className="flex flex-wrap flex-col lg:flex-row items-center justify-center lg:justify-start">
        {responsibleGaming.map(({ href, src, name }) => {
            if (typeof href === 'undefined') {
                return (
                    <img
                        key={`${href}/${src}/${name}`}
                        className="max-h-16 max-w-xs filter grayscale hover:grayscale-0 mb-4 mr-4"
                        src={src}
                        alt={name}
                    />
                )
            }
            return (
                <Link
                    key={`${href}/${src}/${name}`}
                    href={href}
                    rel="noreferrer"
                >
                    <img
                        className="max-h-16 max-w-xs filter grayscale hover:grayscale-0 mb-4 mr-4"
                        src={src}
                        alt={name}
                    />
                </Link>
            )
        })}
    </div>
)

export default PlayResponsible

import React from 'react'
import clsx from 'clsx'
import Image from './Image'

const NavigationArrow = ({
    active,
    disabled,
    className,
    isDisabled,
    ...props
}) => (
    <button
        className={clsx(
            'w-8 h-8 relative outline-none focus:outline-none',
            isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
            className,
        )}
        type="button"
        {...props}
    >
        <Image
            src={isDisabled ? disabled : active}
            layout="fill"
            objectFit="contain"
            maxWidth={64}
        />
    </button>
)

export default NavigationArrow

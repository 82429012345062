import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import SbEditable from 'storyblok-react'
import Image from '../utils/Image'
import StepsContainer from '../utils/StepsContainer'
import Button from '../utils/Button'
import CustomText from '../utils/CustomText'

const PowerPoolHeadingSection = ({
    content,
}) => {
    const {
        logo,
        background,
        image,
        amount,
        cashText,
        ctaLink,
        ctaText,
        description,
        registerText,
        termsAndConditionsText,
        steps,
    } = content

    return (
        <SbEditable content={content}>
            <section className="min-h-screen flex flex-col overflow-hidden relative">
                {background?.filename && (
                    <Image
                        placeholder="blur"
                        src={background.filename}
                        layout="fill"
                        objectFit="cover"
                    />
                )}
                <div className="flex flex-1 items-center justify-between md:min-h-[70vh] relative z-1 container flex-wrap py-8 lg:px-24">
                    <div className="w-full uppercase lg:w-1/2 mb-12 lg:mb-0">
                        {logo?.filename && (
                            <div className="relative -left-1 md:-left-2 w-[406px] h-[101px]">
                                <Image
                                    src={logo.filename}
                                    layout="fill"
                                    maxWidth={406}
                                    objectFit="contain"
                                />
                            </div>
                        )}

                        <div className={
                            clsx([
                                'inline-flex relative pt-1 pb-2 mx-3 text-7xl z-1 px-6 transform skew-x-12 border-[5px] border-yellow bg-black bg-opacity-70 text-yellow font-montserrat leading-none',
                                'md:text-8xl',
                                'lg:px-10 lg:pt-3 lg:pb-4 lg:text-9xl lg:bg-transparent ',
                            ])
                        }
                        >
                            <div className="relative flex items-center justify-center align-middle">
                                {amount}
                            </div>
                        </div>

                        <h2
                            className={clsx([
                                'text-yellow text-5xl ml-7 mt-2 mb-4 font-marcheBackSlant',
                                'md:text-6xl',
                                'lg:text-7xl',
                            ])}
                        >
                            {cashText || <Trans>Cash</Trans>}
                        </h2>

                        <h3
                            className={clsx([
                                'font-marche ml-8 text-xl mb-4 ',
                                'md:text-2xl md:mb-6 md:max-w-[25rem]',
                                'md:text-3xl lg:max-w-[30rem]',
                            ])}
                        >
                            {
                                description
                                || <Trans>Predict who will lead the JPL after match day 4</Trans>
                            }
                        </h3>
                        <Button
                            as="a"
                            href={ctaLink}
                            size="largest"
                            className="mb-2 ml-9 md:ml-10 md:mb-3"
                        >
                            {ctaText || <Trans>Bet now</Trans>}
                        </Button>
                        <span className="font-marche block w-full text-sm ml-10 mb-4 md:ml-12 md:text-base">
                            {termsAndConditionsText || <Trans>* terms & conditions apply</Trans>}
                        </span>

                        <div className="flex ml-8 items-center">
                            <Image
                                src="/img/rocket.svg"
                                width={51}
                                height={51}
                            />
                            <h3 className={clsx([
                                'font-marche flex flex-wrap ml-4 leading-tight text-xl',
                                'md:text-2xl',
                                'lg:text-3xl',
                            ])}
                            >
                                <CustomText
                                    customText={registerText}
                                    customTextClassName="text-yellow font-marcheBackSlant"
                                />
                            </h3>
                        </div>
                    </div>
                    {image?.filename && (
                        <div className="flex justify-center lg:w-1/2 mb-12 lg:mb-0 w-[731px] h-[682px] relative">
                            <Image
                                src={image.filename}
                                layout="fill"
                                maxWidth={731}
                                objectFit="contain"
                            />
                        </div>
                    )}
                </div>
                <StepsContainer steps={steps} />
            </section>
        </SbEditable>
    )
}

export default PowerPoolHeadingSection

import React from 'react'
import SbEditable from 'storyblok-react'
import clsx from 'clsx'
import CustomText from '../utils/CustomText'
import { variant } from '../../utils/index'

const TitleRow = ({
    content,
}) => {
    const {
        text,
        font,
        size,
        hasGlow,
    } = content
    return (
        <SbEditable content={content}>
            <div
                className={clsx(
                    'flex flex-col lg:flex-row items-center uppercase text-white text-center md:text-left leading-none',
                    variant(font, {
                        default: 'font-marche',
                        slanted: 'font-marcheBackSlant',
                    }),
                    variant(size, {
                        smallest: 'text-2xl lg:text-3xl',
                        smaller: 'text-3xl lg:text-4xl',
                        small: 'text-4xl lg:text-5xl',
                        medium: 'text-5xl lg:text-6xl',
                        large: 'text-5xl lg:text-[5rem]',
                        larger: 'text-6xl lg:text-[7rem]',
                        largest: 'text-[5rem] lg:text-[10rem]',
                    })
                )}
            >
                <CustomText
                    className="flex gap-x-8 flex-wrap justify-center md:justify-start"
                    customText={text}
                    customTextClassName={clsx(
                        'text-yellow',
                        hasGlow && 'text-shadow-title',
                    )}
                />
            </div>
        </SbEditable>
    )
}

export default TitleRow

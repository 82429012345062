import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import Link from '../../utils/Link'
import Odds from '../../utils/Odds'

const OddsPageBothTeamsScore = ({
    actionUrl,
    bothTeamsScore = null,
    bothTeamsScoreContent: {
        title,
        yesText,
        noText,
    },
    oddsBackground,
    className,
}) => {
    if (bothTeamsScore === null) {
        return null
    }
    return (
        <div
            className={clsx(
                'grid grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 items-center font-marche uppercase',
                className
            )}
        >
            <div className="col-span-2 lg:col-span-1 text-2xl text-center uppercase lg:text-4xl">
                {title || <Trans>The two teams score</Trans>}
            </div>
            <div className="col-span-1 flex flex-col items-center">
                <div className="text-xl lg:text-4xl text-center">
                    {yesText || <Trans>Yes</Trans>}
                </div>
                <Link href={actionUrl}>
                    <Odds
                        className="cursor-pointer mt-2 lg:mt-7"
                        background={oddsBackground}
                    >
                        {parseFloat(bothTeamsScore.yes).toFixed(2)}
                    </Odds>
                </Link>
            </div>
            <div className="col-span-1 flex flex-col items-center">
                <div className="text-xl lg:text-4xl text-center">
                    {noText || <Trans>No</Trans>}
                </div>
                <Link href={actionUrl}>
                    <Odds
                        className="cursor-pointer mt-2 lg:mt-7"
                        background={oddsBackground}
                    >
                        {parseFloat(bothTeamsScore.no).toFixed(2)}
                    </Odds>
                </Link>
            </div>
        </div>
    )
}

export default OddsPageBothTeamsScore

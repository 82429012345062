import React from 'react'

const Logo = ({
    ...props
}) => (
    <svg
        viewBox="0 0 3367.9 1000"
        {...props}
    >
        <path fill="#fff" d="M319.68 211.32h131l-38.81 187.6h1.61c31.54-46.09 73.59-59 110-59 106.74 0 106.74 97.85 78.43 233.7-34 163.34-93 226.41-181.94 226.41-58.22 0-77.62-35.58-81.67-64.69h-1.61l-10.52 53.37H200zm72.77 493.26c49.33 0 64.69-67.11 79.25-137.46 17-80.06 25.06-131.81-22.65-131.81-44.47 0-60.64 59.84-76 137.47-18.6 84.9-19.41 131.8 19.4 131.8zM768.47 591.38l-5.66 26.68c-13.75 67.94-11.32 101.08 26.68 101.08 34.77 0 49.33-25.07 64.69-80.06h121.3C950.41 748.25 881.68 800 772.51 800c-164.15 0-155.25-116.44-127-245.82 25.88-122.92 79.24-214.29 222.37-214.29 162.53 0 151.21 102.7 121.29 251.49zm110.78-76c12.94-61.45 11.32-94.61-25.07-94.61-42.85 0-57.41 35.58-69.54 94.61zM1081.41 441.78h-54.18l18.6-90.57H1100l26.69-128.57h131L1231 351.21h60.65l-18.6 90.57h-60.65l-46.09 220.75c-6.47 31.54 2.43 40.43 34 40.43a144.12 144.12 0 0018.6-1.61l-17.79 87.33a622.92 622.92 0 01-76.82 4.85c-90.56 0-113.2-16.17-91.37-118.06z" />
        <path fill="#f7df52" d="M1379.22 211.32h334l-22.64 110h-198.16l-24.26 115.64h186l-22.65 110h-186l-50.13 241.78h-135.84zM1754.41 211.32h135.85l-119.67 577.36h-135.85zM1960.62 211.32h228c115.63 0 140.7 71.16 123.72 150.41-17 84.09-59 125.33-126.14 140.69V504c83.28 12.94 78.43 66.31 63.07 143.94-10.51 57.41-16.17 88.14-16.17 106.74 0 17 4.85 22.64 13.74 26.68l-.8 7.28H2099.7c-7.28-19.41-4-51.75 9.71-117.25 19.41-100.27 11.32-121.3-31.54-121.3h-51.75l-49.33 238.55h-135.85zm84.91 243.4h42c54.18 0 79.25-40.43 85.72-75.21 8.89-42.85-1.62-72.77-55.8-72.77h-41.24zM2437.71 612.4l-4.05 20.22c-2.42 10.51-4 21-4 30.72 0 27.5 11.32 46.09 46.9 46.09 46.9 0 69.54-35.58 76-68.73 25.88-123.72-235.31-55.79-192.45-270.89C2379.49 277.63 2445 200 2584.88 200c135.84 0 170.62 69.54 151.21 157.68l-2.43 13.75h-131c5.66-28.3 6.46-48.52.8-60.65-4.85-13.74-17-20.21-36.38-20.21-34.78 0-58.22 20.21-67.12 62.26-25.88 120.49 235.31 59.84 193.26 263.61-28.3 131.81-110 183.56-235.31 183.56-97.84 0-185.17-29.92-159.3-150.4l8.09-37.2zM2888.11 321.29h-121.29l22.64-110h378.44l-22.65 110H3024l-97 467.39h-135.92z" />
    </svg>
)

export default Logo

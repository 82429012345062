import React from 'react'
import { Trans } from '@lingui/macro'
import Image from 'next/image'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import Logo from './Logo'
import Button from './Button'
import LanguageSelectWithState from './LanguageSelectWithState'
import Link from './Link'
import { getHrefFromLink } from '../../utils'

const Header = ({
    currentLanguage,
    onCloseNavigation,
    onOpenNavigation,
    isOpen,
    navigationItems = [],
}) => (
    <header className="fixed z-header top-0 left-0 w-full bg-black">
        <nav className="container mx-auto" aria-label="Top">
            <div className="w-full py-3 xl:py-6 flex items-center justify-between">
                <div className="flex items-center">
                    <Link
                        href="/"
                        className="flex flex-col lg:flex-row items-center"
                    >
                        <span className="sr-only">
                            <Trans>betFIRST FIRST IN FAST</Trans>
                        </span>
                        <Logo className="h-9 lg:h-[4.5rem] lg:ml-0" />
                        <div className="w-28 lg:w-48 lg:mt-2">
                            <Image
                                src="/img/first_in_fast.png"
                                width={222}
                                height={26}
                            />
                        </div>
                    </Link>
                    <div className="hidden ml-12 xl:flex items-center space-x-4 2xl:space-x-12">
                        {navigationItems.map(({ text, link, isActive }) => (
                            <Link
                                key={text}
                                href={getHrefFromLink(link)}
                                className={clsx([
                                    'text-l 2xl:text-xl text-white flex items-center py-6 mr-2 border-b-[3px]',
                                    isActive ? ' border-yellow' : 'border-transparent',
                                ])}
                            >
                                {text}
                            </Link>
                        ))}
                    </div>
                </div>
                <button
                    className="xl:hidden"
                    type="button"
                    onClick={onOpenNavigation}
                >
                    <Image
                        src="/img/hamburger.svg"
                        width={56}
                        height={30}
                    />
                </button>
                <div className="ml-10 space-x-4 hidden xl:flex items-center">
                    <LanguageSelectWithState />
                    <Button
                        variant="secondary"
                        className="w-44"
                    >
                        <Link href={`https://betfirst.dhnet.be/${currentLanguage}/pop-up-login`}>
                            <Trans>Login</Trans>
                        </Link>
                    </Button>
                    <Button
                        variant="primary"
                        className="w-44"
                    >
                        <Link href={`https://betfirst.dhnet.be/${currentLanguage}/?register=1&idaffiliation=1`}>
                            <Trans>Register</Trans>
                        </Link>
                    </Button>
                </div>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className="fixed h-screen w-screen top-0 right-0 xl:hidden"
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: '100%' }}
                        exit={{ opacity: 0, width: 0 }}
                    >
                        <div className="bg-black absolute h-screen left-12 right-0 top-0 backdrop-filter backdrop-blur-2xl bg-opacity-70 py-24 pl-16 overflow-auto">
                            <div className="flex justify-end px-10">
                                <button type="button" onClick={onCloseNavigation}>
                                    <Image
                                        src="/img/close.svg"
                                        width={30}
                                        height={30}
                                    />
                                </button>
                            </div>
                            {navigationItems.map(({ text, link, isActive }) => (
                                <Link
                                    key={text}
                                    href={getHrefFromLink(link)}
                                    className={clsx([
                                        'text-white w-full block pl-3 border-l-[3px] border-transparent text-4xl mb-14',
                                        isActive && ' border-yellow',
                                    ])}
                                >
                                    {text}
                                </Link>
                            ))}
                            <div className="inline-flex flex-col">
                                <LanguageSelectWithState
                                    className="mb-14"
                                />
                                <Button
                                    variant="secondary"
                                    size="large"
                                    className="mb-9 text-2xl"
                                >
                                    <Link
                                        href={`https://betfirst.dhnet.be/${currentLanguage}/pop-up-login`}
                                    >
                                        <Trans>Login</Trans>
                                    </Link>
                                </Button>
                                <Button
                                    variant="primary"
                                    size="large"
                                    className="text-2xl"
                                >
                                    <Link
                                        href={`https://betfirst.dhnet.be/${currentLanguage}/?register=1&idaffiliation=1`}
                                    >
                                        <Trans>Register</Trans>
                                    </Link>
                                </Button>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </nav>
    </header>
)
export default Header

import { gql, useQuery } from '@apollo/client'

export const query = gql`
    query oddsPageQuery($gameId: Int!) {
        odds(gameId: $gameId) {
            gameId
            odds {
                home
                draw
                away
            }
            totalGoals {
                under
                over
            }
            bothTeamsScore {
                yes
                no
            }
            urls {
                nl
                fr
                en
            }
            teamAway {
                slug
                nl
                fr
                en
            }
            teamHome {
                slug
                nl
                fr
                en
            }
        }
    }
`

const useOddsPageQuery = (options) => {
    const skip = !options.variables.gameId
    const {
        data,
        error,
        loading,
    } = useQuery(query, {
        ...options,
        skip
    })

    if (loading) {
        return {
            loading,
        }
    }

    if (typeof error !== 'undefined') {
        return {
            error,
            loading,
        }
    }

    return {
        ...data && data.odds,
        error,
        loading
    }
}

export default useOddsPageQuery

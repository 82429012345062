import React from 'react'
import Placeholder from './Placeholder'
import TitleRow from './TitleRow'
import Button from './SbButton'
import Image from './SbImage'
import HeadingGroup from './HeadingGroup'

const Components = {
    TitleRow,
    Button,
    Image,
    HeadingGroup,
}

const DynamicHeading = ({ blok }) => {
    if (typeof Components[blok.component] !== 'undefined') {
        const Component = Components[blok.component]
        return (
            <Component content={blok} />
        )
    }
    return <Placeholder componentName={blok.component} />
}

export default DynamicHeading

import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import { LANGUAGE_EN } from '../../../utils/constants'
import useLanguageState from '../../hooks/useLanguageState'
import Image from '../../utils/Image'

const OddsPageDetails = ({
    teamAway,
    teamHome,
    logo,
    subTitle,
    vsText,
    className,
}) => {
    const { locale } = useLanguageState()
    const defaultTeamHome = teamHome[LANGUAGE_EN]
    const defaultTeamAway = teamAway[LANGUAGE_EN]
    let teamHomeTranslated = teamHome[locale]
    if (teamHomeTranslated === null) {
        teamHomeTranslated = defaultTeamHome
    }
    let teamAwayTranslated = teamAway[locale]
    if (teamAwayTranslated === null) {
        teamAwayTranslated = defaultTeamAway
    }
    return (
        <div
            className={
                clsx(
                    'flex flex-col items-center',
                    className,
                )
            }
        >
            {logo?.filename && (
                <div className="mb-3">
                    <Image
                        src={logo.filename}
                        width={482}
                        height={80}
                        className="h-8 lg:h-20"
                    />
                </div>
            )}
            {subTitle && (
                <h3 className="text-yellow font-marcheBackSlant uppercase text-xl lg:text-4xl text-center">
                    {subTitle}
                </h3>
            )}
            <div className="flex flex-col items-center lg:flex-row mt-6 lg:mt-10 z-1">
                <h2 className="font-marcheBackSlant uppercase text-3xl lg:text-5xl w-80 text-center lg:text-right">
                    {teamHomeTranslated}
                </h2>
                <span className="px-4 mx-0 lg:mx-5 my-2 lg:my-0 font-montserrat uppercase transform skew-x-12 bg-yellow text-black text-4xl lg:text-8xl">
                    {vsText}
                </span>
                <h2 className="font-marcheBackSlant uppercase text-3xl lg:text-5xl w-80 text-center lg:text-left">
                    {teamAwayTranslated}
                </h2>
                <span className="flex lg:hidden font-marcheBackSlant uppercase text-2xl text-yellow mt-8">
                    <Trans>Tap to bet</Trans>
                </span>
            </div>
        </div>
    )
}

export default OddsPageDetails

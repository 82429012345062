import React from 'react'
import clsx from 'clsx'

const Placeholder = ({
    componentName,
    className,
}) => (
    <div className={clsx(
        'py-4 border border-red-200 bg-red-100',
        className,
    )}
    >
        <p className="text-red-700 italic text-center">
            The component <strong>{componentName}</strong> has not been created yet.
        </p>
    </div>
)

export default Placeholder

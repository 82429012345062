import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import useLanguageState from '../hooks/useLanguageState'
import Header from './Header'
import usePageProps from '../hooks/usePageProps'

const HeaderWithState = ({
    content,
}) => {
    const { data } = usePageProps()
    const finalContent = content || data.headerStory.content
    const { locale } = useLanguageState()
    const [isOpen, setIsOpen] = useState(false)

    const handleCloseNavigation = () => {
        setIsOpen(false)
    }
    const handleOpenNavigation = () => {
        setIsOpen(true)
    }

    return (
        <SbEditable content={finalContent}>
            <Header
                currentLanguage={locale}
                onCloseNavigation={handleCloseNavigation}
                onOpenNavigation={handleOpenNavigation}
                isOpen={isOpen}
                {...finalContent}
            />
        </SbEditable>
    )
}

export default HeaderWithState
